import { createApp } from 'vue'

import {
    Button, Calendar, Badge, ConfigProvider, Form, Input, InputNumber, Checkbox, Modal, Menu, PageHeader,
    Layout, Breadcrumb, Row, Col, Avatar, Alert, Radio, Switch, Tabs, DatePicker, TimePicker,
    Space, Popconfirm, Affix, List, Table, Tag, Divider, Pagination, Upload, Image, Select,
    Tooltip,
} from 'ant-design-vue';

import App from './App.vue';
import router from './router/router';
import i18n from "@/lang/index.js";

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

const app = createApp(App);
/* 会自动注册 Button 下的子组件, 例如 Button.Group */

app.use(router);
app.use(i18n);
app.use(Button);
app.use(Calendar);
app.use(Badge);
app.use(ConfigProvider);
app.use(Form);
app.use(Input);
app.use(InputNumber);
app.use(Checkbox);
app.use(Modal);
app.use(Menu);
app.use(PageHeader);
app.use(Breadcrumb);
app.use(Row);
app.use(Col);
app.use(Avatar);
app.use(Layout);
app.use(Alert);
app.use(Radio);
app.use(Switch);
app.use(Tabs);
app.use(DatePicker);
app.use(TimePicker);
app.use(Space);
app.use(Popconfirm);
app.use(Affix);
app.use(List);
app.use(Table);
app.use(Tag);
app.use(Divider);
app.use(Pagination);
app.use(Upload);
app.use(Image);
app.use(Select);
app.use(Tooltip);


app.mount('#app');

