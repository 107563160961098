export default {
  message:{
    homePage:'Home',
    logout:'Logout',
    show_history:'Show History',
    confirm_delete_todo:'Delete Record?',
    add_todo:'Add Todo',
  },
  menu:{
    data:'Data',
    dataItem:'Item',
    dataItemNew:'New Item',
  },
  bn:{
    new:'New',
    copy:'Copy',
    delete:'Delete',
    history:'History',
  },
  o:{
    event_type:{
      note:'Note',
      activity:'Activity',
      asset:'Asset',
      asset_current:'Current',
      asset_deposit:'Deposit',
      asset_national_debt:'National Debt',
      liabilities:'Liabilities',
      liabilities_daily_expenses:'Daily Expenses',
      liabilities_household:'Household',
      liabilities_credit_card:'Credit Card',
      festival:'Festival',
      festival_birthday:'Birthday',
      festival_anniversary:'Anniversary',
      festival_others:'Others',
    },
    festival_type:{
      birthday: 'Birthday',
      anniversary: 'Anniversary',
      others: 'Others',
    },
    repeat_type:{
      no_repeat: 'No Repeat',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      lunar_year: 'Lunar Year',
    },
    money_sign:{
      income:'Income',
      expense:'Expense',
      others:' ',
    },
    done:{'done':'Done','not done':'Not Done','-':'-',},
  },
}