import {TableClass} from "@/utils/TableClass.js";
import {DateTime} from "@/utils/DateTime.js";
import {DOM} from "@/utils/DOM.js";
import {List} from "@/utils/List.js";
import {Dict} from "@/utils/Dict.js";
import {Router} from "@/utils/Router.js";
import {API} from "@/utils/API.js";
import {Calendar} from "./Calendar.js";
import {User} from "./User.js";
import {URL} from "@/utils/URL.js";
import icons from "@/utils/icons";

export class Utils {
    constructor(router, cookies, moment){
        this.moment = moment;
        this.cookies = cookies;
        this.icons=icons;
        this.list = new List(this);
        this.dict = new Dict(this);

        this.table =  new TableClass(this);
        this.datetime = new DateTime(moment);
        this.dom = new DOM(this);

        this.router = new Router(router);
        this.api = new API(router);

        this.user = new User();
        this.calendar = new Calendar(this)
        this.url = new URL(this)
    }
}
