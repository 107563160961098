import axios from 'axios'
// let baseURL = "http://localhost:8000/api/"
// let mediaURL = "http://localhost:8000"
let baseURL = "https://www.gnok.cc/api/"

  // 1.创建axios的实例
const instance = axios.create({
  baseURL:baseURL,
  // baseURL:"https://www.gnok.cc/api/",
  timeout: 20000,
})
instance.token = '';
instance.baseURL = baseURL;

    // 2.1.请求拦截的作用
instance.interceptors.request.use(config => {
  if (instance.token){
    config.headers.Authorization = instance.token;
  }
  return config
}, error => {
  return Promise.reject(error);
})

// 2.2.响应拦截
instance.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.reject(error);
})

// 3.发送真正的网络请求

export default instance;
