import {ref} from 'vue';

export class DOM {
    constructor(utils){
        this.utils = utils
        this.select_options_money_sign=[{value:1, label:'收入'},{value:0, label:' '},{value:-1, label:'支出'}]
        this.static_data_ready = false;
        this.choices={};
        this.options={};
        this.blank_option={value: null, label:'-'}
    }

    page_title(document, title){
        document.title = document.title + ' - ' + title
    }

    focus_el_nextTick(thisObj, ref) {
      thisObj.$nextTick(()=>{
        if (thisObj.$refs[ref].length){
          thisObj.$refs[ref][0].focus();
        }
      })
    }

    get_static(t, user){
        if (!this.static_data_ready){
            this.utils.api.get_static_data()
                .then(response => {
                    for (let key in response.data.Options){
                        this.update_choices(response.data.Options[key])
                        this.select_options(response.data.Options[key], t)
                    }
                    user.islogin=true;
                    this.static_data_ready = true;
                })
        }
    }

    update_choices(column){
        let choices = column.choices
        let obj = {};
        for (let i=0;i<choices.length;i++){
            if (choices[i][0] === ''){continue;}
            obj[choices[i][1]]=choices[i][0]
        }
        this.choices[column.key] = obj
    }

    select_options_from_cache(field_name){
        if (this.options[field_name]){return this.options[field_name]}
        return [this.blank_option]
    }

    select_options(column, t=null, reusable=true){
        if (this.options[column.key]){return this.options[column.key]}
        if (!column.choices) return [this.blank_option]
        let options = null;
        if (column['group_choices']){
            options =  this.select_options_by_group(column.key, column.choices,t);
        }else{
            options = this.select_options_no_group(column.key, column.choices,t);
        }
        if (column['null']) options.unshift(this.blank_option)
        if (reusable) this.options[column.key]=options;
        return options
    }


    filter_options(column){
        if (this.options[column.key]){
            let options = [this.blank_option]
            options = options.concat(this.options[column.key]);
            return options
        }else{
            return this.select_options(column, null,false);
        }
    }

    select_options_no_group(field, choices, t){
        let options = [];
        for (let i=0;i<choices.length;i++){
            if (choices[i][0] === ''){continue;}
            if (t===null){
                options.push({value: choices[i][0], label: choices[i][1]})
            }else{
                options.push({value: choices[i][0], label: t('o.'+ field + '.' + choices[i][1])})
            }
        }
        return options
    }

    select_options_by_group(field, choices, t){
        let options = [];
        let groups = [];
        let cur_group = {id:-1, label:'', options:[]}
        for (let i=0;i<choices.length;i++){
            if (choices[i][0] === ''){continue;}

            let option=choices[i]
            let value=option[0]
            let label=option[1]
            let group_id = Math.floor(value/1000)

            if (group_id === cur_group.id){
                cur_group.options.push(option)
            }else {
                if (t===null){
                    cur_group = {id:group_id, label:label, options:[option]}

                }else{
                    cur_group = {id:group_id, label:t('o.'+ field + '.' + label), options:[option]}
                }
                groups.push(cur_group)
            }
        }

        for (let k=0;k<groups.length;k++){
            let group=groups[k];
            if (group.options.length>1){group.options.shift()}
            options.push({label:group.label, options:this.select_options_no_group(field, group.options, t)})
        }
        return options
    }


    select_options_dict(source){
        let options = [];
        for (let key in source){
            options.push({value: source[key].value, label: source[key].text})
        }
        return options
    }

    select_options_by_dict(source){
        let options = [];
        let groups = [];
        let cur_group = {id:-1, label:'', options:[]}
        for (let key in source){
            let option=source[key]
            let value=option.value
            let label=option.text
            let group_id = Math.floor(value/1000)

            if (group_id === cur_group.id){
                cur_group.options.push(option)
            }else {
                cur_group = {id:group_id, label:label, options:[option]}
                groups.push(cur_group)
            }
        }

        for (let k=0;k<groups.length;k++){
            let group=groups[k];
            if (group.options.length>1){group.options.shift()}
            options.push({label:group.label, options:this.select_options_dict(group.options)})
        }
        return options
    }

    select_filterOption(input, option){
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    select_Option_label(column, obj, t){
        // console.log('select_Option_label', typeof(obj), obj)
        let options = this.select_options(column, t);
        let value = (typeof(obj) === 'object')?obj[column.key]:obj;
        if (typeof(value) ==='boolean'){value=value?1:0}
        // console.log('select_Option_label', options, value)
        for (let i=0; i<options.length;i++){
            if (options[i].options === undefined){
                if(options[i].value===value){return  options[i].label}
            }else {
                for (let j=0; j<options[i].options.length;j++){
                    if(options[i].options[j].value===value){return  options[i].options[j].label}
                }
            }
        }
        return ''
    }


    page_header_init(page_header){
        page_header.buttons=[];
        page_header.switches=[];
        page_header.visible=true;
    }

    button(fn=null, text='', icon='', hint='', popconfirm='',
               shape='circle', size='small', ghost=false){
        let button = {fn:fn, text:text, hint:hint, icon:icon, popconfirm:popconfirm, shape:shape, size:size, ghost:ghost}
        if (shape==='') {delete button.shape}
        return button;
    }

    switch(checked=false, click=null, true_text='', false_text=''){
        return {checked:ref(checked), click:click, true_text:true_text, false_text:false_text}
    }

}
