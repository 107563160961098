import request from "@/network/request";

export class URL {
    constructor(utils){
        this.utils = utils;
    }

    full_url(org_url, server=''){
        if (!server){server = request.baseURL;}

        const trimmedBaseURL = server.replace(/\/+$/, ''); // 移除结尾的斜杠
        const trimmedEndpoint = org_url.replace(/^\/+/, ''); // 移除开头的斜杠
        return trimmedBaseURL + '/' + trimmedEndpoint;
    }
}