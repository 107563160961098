<template>

<div style="margin-right: 24px;">
  <a-badge v-if="user.islogin"  :count="0" style="cursor: pointer;" >
    <a-avatar @click="showModal" shape="circle" :src="user.avatar"></a-avatar>
  </a-badge>
  <a-avatar v-else @click="showModal" style="cursor: pointer" shape="circle">登录</a-avatar>
</div>


<a-modal
  v-model:visible="visible"
  :footer="null"
  title="登录"
  >
    <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
        v-if="!user.islogin"
    >
        <a-form-item
        label="Username"
        name="username"
        :rules="[{ required: true, message: 'Please input your username!' }]"
        >
        <a-input v-model:value.trim="formState.username" />
        </a-form-item>

        <a-form-item
        label="Password"
        name="password"
        :rules="[{ required: true, message: 'Please input your password!' }]"
        >
        <a-input-password v-model:value="formState.password" />
        </a-form-item>


<a-form-item v-if="formState.errmsg" :wrapper-col="{ offset: 8, span: 16 }">
  {{ formState.errmsg }}
</a-form-item>
<a-form-item :wrapper-col="{ offset: 8, span: 16 }">
  <a-button type="primary" html-type="submit" :loading="bnLoginLoading">登录</a-button>
</a-form-item>
    </a-form>


<div v-if="user.islogin" style="text-align: center">
  <a-avatar shape="circle" :size="128" :src="user.avatar"></a-avatar>
  <p></p>
  <a-space>
    <a-upload name="avatar" :customRequest="user_upload_avatar" :show-upload-list="false" >
      <a-button>更新头像</a-button>
    </a-upload>
    <a-button type="primary" @click="handleLogOut">{{$t("message.logout")}} ({{ user.username }})</a-button>
  </a-space>
  <p></p>


    <a-form v-if="user.profile"
    :model="user.profile"
    name="basic"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 20 }"
    autocomplete="off">

    <a-form-item :label="$t('field.wechat_id')" name="wechat_id" :rules="[{ required: true, message: '请输入微信号' }]">
      <a-input v-model:value="user.profile.wechat_id" @blur="save_userprofile"/>
    </a-form-item>
    <a-form-item :label="$t('field.phone')" name="wechat_id" :rules="[{ required: true, message: '请输入电话号码' }]">
      <a-input v-model:value="user.profile.phone" @blur="save_userprofile"/>
    </a-form-item>

    </a-form>
</div>
</a-modal>
</template>
<script>
import {defineComponent, reactive, ref, inject} from 'vue';
import request from "@/network/request";
import cookies from "vue-cookies";

export default defineComponent({
  setup() {
    const t = inject('t');
    let message = inject('message');
    const user = inject('user');
    const calendar = inject('calendar');
    const calFn = inject('calendarFn');
    const utils = inject('utils');
    const bnLoginLoading = ref(false);
    const visible = ref(false);
    const formState = reactive({username: '', password: '', errmsg:'', remember: true,});
    auto_login()

    function auto_login(){
      let saved_token = localStorage.getItem('token');
      if (!saved_token){saved_token = cookies.get('token');}

      if (!saved_token){
        handleLogOut(false)
      }else {
        request.token = saved_token
        request.post('/login_with_token/')
            .then(response => {
              handleLogIn(response.data);
            })
          .catch(err=>{
            console.log('api_user_login_with_token', err);
            message.error('login failed')
          });
      }
    }

    const showModal = () => {
      visible.value = true;
    };

    const handleLogIn = (data) => {
      if (data.token != null){
          let token = 'Token ' + data.token;
          cookies.set('token', token)
          localStorage.setItem('token', token);
          request.token = token
        }

      user.username=data.username;
      user.id = data.id;
      user.avatar = utils.url.full_url(data.avatar);
      user.userprofile_id = data.userprofile_id
      utils.dom.get_static(t, user)
      get_userprofile()

      // calFn.get_static(calendar);

      formState.username = '';
      formState.password = '';
    };

    function get_userprofile(){
      if (!user.userprofile_id) return;

      utils.api.get_userprofile(user.userprofile_id)
          .then(response => {
            user.profile = response.data
          })
          .catch(err=>{
            message.error(err)
        });
    }

    function save_userprofile(){
      if (!user.userprofile_id || !user.profile) return;

      utils.api.save_userprofile(user.profile)
          .then(response => {
            user.profile = response.data
          })
          .catch(err=>{
            message.error(err)
        });
    }

    function handleLogOut(show_message=true){
      // cookies.remove('token')
      cookies.set('token', '')
      localStorage.clear()
      request.token = ''
      user.username='';
      user.id = 0;
      user.avatar = ''
      user.islogin=false;
      calFn.clear(calendar);

      visible.value = false;
      formState.username = '';
      formState.password = '';

      if (show_message)  message.success('已退出');
    }

    function onFinish() {
      bnLoginLoading.value =true;
      let data = {'username':formState.username, 'password': formState.password}
      request.post('/login/', data)
          .then(response => {
            handleLogIn(response.data)
            visible.value = false;
            message.success('登录成功');
          })
        .catch(err=>{
          formState.errmsg = err.response.data.non_field_errors[0];
          message.error(formState.errmsg);
          handleLogOut();
        })
        .finally(()=>{
          bnLoginLoading.value =false;
        });
    }

    const onFinishFailed = errorInfo => {
      message.warning('Failed:', errorInfo);
    };

    const beforeUpload = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }

      return isJpgOrPng && isLt2M;
    };

    const user_upload_avatar = (info) => {
      utils.api.upload(info, ['User','userprofile'], user.id, 'avatar')
          .then(response => {
            user.avatar = utils.url.full_url(response.data);
          })
          .catch(err=>{
            message.error(err)
        });
    };

    return {
      visible,
      showModal,
      calFn,

      formState,
      onFinish,
      onFinishFailed,
      handleLogOut,
      bnLoginLoading,
      user,
      beforeUpload,
      user_upload_avatar,
      save_userprofile,
    };
  },

});
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>