import router from "@/router/router";

export class Router {
    constructor(router){
        this.router = router;
    }

    push(name, query={}){
        this.router.push({name:name, query})
    }

    link_with_pk(route_name, pk){
        this.router.push(route_name, {id: pk});
    }

    get query_id(){
        let id = parseInt(this.router.currentRoute.value.query.id);
        if (isNaN(id) || id<0 ){return 0}
        console.log('query_id', id)
        return  id
    }

    query(key){return this.router.currentRoute.value.query[key];}

    main_calender(){
        this.router.push({name:'calendar'})
    }

    go_back(){
        // router.back(-1);
        if (router.history.length<2){this.main_calender();return;}
        let current_level = router.history[0].meta.level
        for (let k=1;k<router.history.length;k++){
            if (router.history[k].meta.level < current_level){
                // console.log(router.history[k])
                router.push(router.history[k]);
                return;
            }
        }
        this.main_calender()
    }

    test(name, ...key){
        console.log(name, key)
    }

    edit_event(id,date=''){
        let query = {id: id}
        if (date){query['date']=date}
        this.router.push({name:'calendar_event', query})
    }

    edit_asset(id){
        let query = {id: id}
        this.router.push({name:'Asset', query})
    }
}