import icon_add from '@/assets/icon/add.svg'
import icon_close from '@/assets/icon/close.svg'
import icon_copy from '@/assets/icon/copy.svg'
import icon_del from '@/assets/icon/del.svg'
import icon_edit from '@/assets/icon/edit.svg'
import icon_filter from '@/assets/icon/filter.svg'
import icon_filter_clear from '@/assets/icon/filter_clear.svg'
import icon_ok from '@/assets/icon/ok.svg'
import icon_ok_circle from '@/assets/icon/ok_circle.svg'
import icon_sorting from '@/assets/icon/sorting.svg'
import icon_sorting_down from '@/assets/icon/sorting_down.svg'
import icon_sorting_up from '@/assets/icon/sorting_up.svg'
import icon_history from '@/assets/icon/history.svg'
import icon_refresh from '@/assets/icon/refresh.svg'
import icon_previous_alt from '@/assets/icon/previous-alt.svg'

const icons = {}
icons.add = icon_add;
icons.close = icon_close;
icons.copy = icon_copy;
icons.del = icon_del;
icons.edit = icon_edit;
icons.filter = icon_filter;
icons.filter_clear = icon_filter_clear;
icons.ok = icon_ok;
icons.ok_circle = icon_ok_circle;
icons.sorting = icon_sorting;
icons.sorting_down = icon_sorting_down;
icons.sorting_up = icon_sorting_up;
icons.history = icon_history;
icons.refresh = icon_refresh;
icons.previous_alt = icon_previous_alt;


export default icons