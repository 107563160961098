<template>

  <a-row :wrap="false">
    <a-col  flex="100px">
    <img alt="logo" src="../assets/logo.png"
             style="width: 100px; margin-left: 80px; cursor: pointer"
             @click="utils.router.main_calender()">
    </a-col>
    <a-col flex="auto">
    <a-menu v-if="user.islogin" v-model:selectedKeys="current" mode="horizontal"
            style="border: 0px;"
              overflowedIndicator="<span>···</span>"
      >

        <a-sub-menu key="data">
          <template #icon>
            <database-outlined  class="MenuIcon"/>
          </template>
          <template #title>{{$t('menu.data')}}</template>
          <a-menu-item-group :title="$t('menu.data_calendar')">
            <a-menu-item key="data:11" @click="utils.router.push('calendar_event_list')" >{{$t('menu.data_calendar_event_list')}}</a-menu-item>
          </a-menu-item-group>

          <a-menu-item-group :title="$t('menu.data_assets_management')">
            <a-menu-item key="data:21" @click="utils.router.push('asset_management_asset_list')" >{{$t('menu.data_management_assets_list')}}</a-menu-item>
            <a-menu-item key="data:22" @click="utils.router.push('asset_management_meter_list')" >{{$t('menu.data_management_meter_list')}}</a-menu-item>
          </a-menu-item-group>

        </a-sub-menu>

        <a-sub-menu key="report">
          <template #icon>
            <FundProjectionScreenOutlined  class="MenuIcon"/>
          </template>
          <template #title>报告</template>
          <a-menu-item-group title="状态">
            <a-menu-item key="report:2" @click="utils.router.push('ReportBackgroundJobs')" >后台任务</a-menu-item>
          </a-menu-item-group>
        </a-sub-menu>

        <a-sub-menu key="money">
          <template #icon>
            <MoneyCollectOutlined class="MenuIcon"/>
          </template>
          <template #title>资产管理</template>
          <a-menu-item-group title="报告">
            <a-menu-item key="money:1" @click="utils.router.push('ReportCashFlow')" >现金流</a-menu-item>
            <a-menu-item key="money:2" @click="utils.router.push('ReportBalanceSheet')" >资产 - 负债</a-menu-item>
          </a-menu-item-group>
        </a-sub-menu>

        <a-sub-menu key="system">
          <template #icon>
            <SettingOutlined class="MenuIcon"/>
          </template>
          <template #title>系统</template>
          <a-menu-item-group title="状态">
            <a-menu-item key="system:1" @click="utils.router.push('ReportBackgroundJobs')" >后台任务</a-menu-item>
          </a-menu-item-group>
          <a-menu-item-group title="语言">
            <a-menu-item key="system:2" @click="setLang('zh_CN')">中文</a-menu-item>
            <a-menu-item key="system:3" @click="setLang('en_US')">english</a-menu-item>
          </a-menu-item-group>
        </a-sub-menu>

      </a-menu>
    </a-col>
    <a-col flex="50px" align="end">
      <UserInfo/>
    </a-col>
  </a-row>



</template>
<script>
import { defineComponent, getCurrentInstance, ref , inject, } from 'vue';
import UserInfo from './UserInfo.vue'
import { SettingOutlined, FundProjectionScreenOutlined, MoneyCollectOutlined, DatabaseOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    SettingOutlined,FundProjectionScreenOutlined,MoneyCollectOutlined,DatabaseOutlined,
    UserInfo,
  },


  setup() {
    let user = inject('user');
    let utils = inject('utils');
    const { proxy } = getCurrentInstance()

   function setLang(lang) {
      if (lang === 'en_US') {proxy.$i18n.locale = 'en_US';}else{proxy.$i18n.locale = 'zh_CN';}
    }

    const current = ref(['mail']);
    return {
      current,
      utils,
      user,
      setLang,
    };
  },

});
</script>

<style scoped>
.MenuIcon{
  font-size: large;
}
</style>