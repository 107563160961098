import {createRouter, createWebHistory} from 'vue-router'
import routes from './routes.js'

const router = createRouter({
    history: createWebHistory(),
    routes
})


router.history = [];
router.beforeEach((to, from, next) => {
    router.history.unshift(to)
    next()
})


export default router