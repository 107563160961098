<template>

<a-layout class="layout" >
  <a-layout-header class="header">
    <main_menu/>
  </a-layout-header>
  <a-layout-content class="content">
    <a-config-provider :locale="zh_CN"  v-if="user.islogin" >
      <router-view :key="$route.fullPath"></router-view>
    </a-config-provider>
  </a-layout-content>
  <a-layout-footer class="footer">
  </a-layout-footer>
</a-layout>

</template>
<script>
import { defineComponent, provide, reactive } from "vue";
import {useRouter} from "vue-router";
import { useI18n } from 'vue-i18n/index'//要在js中使用国际化
import cookies from 'vue-cookies'

import main_menu from '@/components/MainMenu.vue'

import { message } from 'ant-design-vue';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';

import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {Utils} from "@/utils/Utils.js";

dayjs.locale('zh-cn');

export default defineComponent({
  components: {
    main_menu,
    // calendar_com,
  },

  setup() {
    const { t } = useI18n()
    provide('t', t);

    provide('message', message);
    provide('cookies', cookies);
    provide('dayjs', dayjs);
    provide('moment', moment);


    const router = useRouter();
    provide('router', router);

    const utils = new Utils(router,cookies, moment)
    provide('utils', utils)

    const calendarFn = utils.calendar;
    provide('calendarFn', calendarFn);

    const calendar = reactive(utils.calendar.new_data());
    provide('calendar', calendar);

    const user = reactive(utils.user.new_data());
    provide('user', user);

    const page_header = reactive({visible:false, buttons:[], switches:[]});
    provide('page_header', page_header);

    return {
      calendar,
      user,
      zh_CN,
    };
  },


});
</script>
<style scoped>
#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.layout{
  background: #fff;
}

.header {
  background: #fff;
  padding: 0 ;
  box-shadow:0px 2px 5px 0px whitesmoke;
}

.content{
  margin:5px 0px 0px 0px;
  padding:1em;
  background: #fff;
  min-height:360px;
}

.footer{
  text-align: center;
  background: white;
}
</style>
