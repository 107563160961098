export class DateTime {
    constructor(moment){
        this.moment = moment;
    }

    localtime(time_string){
        return this.moment(time_string).format('yyyy-MM-DD hh:mm:ss')
    }

    isValidDate(date) {
        return date instanceof Date && !isNaN(date.getTime())
    }
}
