export class User {
    constructor(){

    }

    new_data(){
        return{
            username: "",
            email: "",
            islogin:false,
            token:null,
            avatar: '',
            id:0,
        };
    }

}


