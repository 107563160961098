export class TableClass {
    constructor(utils){
        this.utils = utils;
    }

    new_data(default_filters={}, default_sorting={}){
        return {
            paginator:{current:1, total:0, size:10, start_number:1},
            columns:[],
            data:[],
            title:'',
            show_history: false,
            show_sum_row: false,
            show_refresh_time: false,
            is_loading:false,
            fn_refresh:null,
            filters:{},
            sorting:{},
            default_filters:default_filters,
            default_sorting:default_sorting,
        };
    }

    set_data(ref_report, response, fn_refresh, title='', show_refresh_time=false){
        let data = this.new_data()
        this.utils.dict.sync(data, response.data)
        data.fn_refresh = fn_refresh;
        data.title = title
        data.show_refresh_time = show_refresh_time
        data.show_history = (data.filters.show_history===1)

        ref_report.value = data
        console.log('table class.js set_data', ref_report.value)
    }

    api_para(ref_report){
        let para = {'PageNumber': ref_report.value.paginator.current, 'PageSize': ref_report.value.paginator.size,}
        if(ref_report.value.filters){para['Filters']=ref_report.value.filters}
        if(ref_report.value.sorting){para['Sorting']=ref_report.value.sorting}
        return para
    }

    column_set_header_switch(column, true_text='', false_text='',  checked=false){
        column.head_control='switch';
        column.head_true_text=true_text;
        column.head_false_text=false_text;
        column.head_checked=checked;
    }

    column_set_switch(column, true_text='', false_text=''){
        column.control='switch';
        column.true_text=true_text;
        column.false_text=false_text;
    }

    column_set_header_button(column, icon, popconfirm='', hint=''){
        column.head_control='button';
        column.head_icon=icon;
        column.head_popconfirm=popconfirm;
        column.head_hint=hint;
    }

    column_set_button(column, icon, popconfirm='', hint=''){
        column.control='button';
        column.icon=icon;
        column.popconfirm=popconfirm;
        column.hint=hint;
    }

    column_set_link(column){
        column.control='link';
    }

    column_set_select(column, options){
        column.control='select';
        column.options=options;
    }

    datatype_is_datetime(column){
        return column.datatype.startsWith('Date')
    }

    datatype_is_float(column){
        return column.datatype === 'FloatField'
    }

    datatype_is_int(column){
        return column.datatype === '"IntegerField"'
    }

    datatype_is_bool(column){
        return column.datatype === 'BooleanField'
    }

    datatype_is_foreignkey(column){
        return column.datatype === 'ForeignKey'
    }

    cell_editable(column, obj){
       return column.editable && !obj[column.key + '_disabled']
    }

    switch_cell(column, obj){
        if (!this.cell_editable(column, obj)) return false;
        return this.datatype_is_bool(column);
    }

    select_cell(column, obj=null){
        if (obj){
            if (!this.cell_editable(column, obj)) return false;
        }else{
            if (!column.editable) return false;
        }
        if (this.datatype_is_foreignkey(column)) return true;
        return column.choices;
    }

}