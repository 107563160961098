export class List {
    constructor(utils){
        this.utils = utils
    }

    obj_list_filterByName(source_list, obj_key, obj_key_value) {
        let result = []
        for(let i = 0; i < source_list.length; i++) {
            if (source_list[i][obj_key] === obj_key_value){
                result.push(source_list[i])
            }
        }
        return result
    }

    sync(target, source){
        target.length = 0;
        for(let i=0; i<source.length; i++) {
            target.push(source[i]);
        }
    }

    sync_for_DOM(target, source){
        target.length = 0;
        for(let k=0;k<source.length;k++){
            let dictObj = {};
            this.utils.dict.sync_for_DOM(dictObj, source[k]);
            target.push(dictObj);
        }
    }
}
