export class Calendar {
    constructor(utils){
        this.utils = utils;
        this.list = utils.list;
        this.dict = utils.dict;
        this.moment = utils.moment;
        this.api = utils.api;
    }

    day_common(calendar, moment_obj){
        let yyyy_MM_DD = moment_obj.format('yyyy-MM-DD');
        let cell_data = {};
        let cell_common = {};

        cell_data.day = moment_obj.format('DD');
        cell_data.month = moment_obj.format('MM');
        cell_data.weekday = moment_obj.weekday();

        for (let i=0; i<calendar.common.length; i++){
            if (calendar.common[i].date === yyyy_MM_DD){
                cell_common=calendar.common[i];
                break;
            }
        }
        return {
            ...cell_data,
            ...cell_common,
        } ;
    }

    day_events(calendar, cell_moment){
      let event_list = [];
      let cell_date = cell_moment.startOf('day').toDate();
      for (let i=0; i<calendar.events.length; i++){

        if (calendar.events[i].from_time){
          if (cell_date < this.moment(calendar.events[i].from_time).startOf('day').toDate()){
            continue;
          }
        }

        if (calendar.events[i].to_time){
          if (cell_date > this.moment(calendar.events[i].to_time).startOf('day').toDate()){
            continue;
          }
        }

        event_list.push(calendar.events[i]);
      }
      return event_list;
    }

    update_calendar(calendar, to_moment){
        // console.log('update_calendar')
        calendar.updating=true;
        this.list.sync(calendar.events, [])
        this.api.get_calendar_content(to_moment.format('yyyy/MM/DD/'), {'year': to_moment.format('yyyy'), 'month': to_moment.format('MM')})
            .then(response => {
                this.list.sync(calendar.events, response.data.events)
                this.list.sync(calendar.common, response.data.common)
                this.dict.sync(calendar.eventTemplate, response.data.event)
                calendar.selected_day_common = this.day_common(calendar, to_moment);
                calendar.data_ready=true
            })
            .catch(err=>{
                this.list.sync(calendar.common, [])
                console.log('api_calendar_common err', err)
                calendar.data_ready=false
            })
            .finally(()=>{
                calendar.updating=false
                calendar.need_update = false
            });
    }

    new_data(){
        return{
            selected_moment: this.moment(new Date()),
            selected_day_common:{},
            common: [],
            events: [],
            eventState:{},
            eventTemplate:{},
            Options: {},
            data_ready: false,
            updating: false,
            need_update: false,
            static_data_ready: false,
            year:2000,
            month:1,
        }
    }

    clear(calendar){
        this.list.sync(calendar.events, []);
        this.list.sync(calendar.common, []);
        calendar.data_ready=false;
        calendar.updating=false;
        calendar.need_update=false;
        calendar.static_data_ready=false;
    }
}