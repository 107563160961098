import {createI18n} from "vue-i18n/index";
import cn from './cn.js'
import en from './en.js'

const i18n = createI18n({
  fallbackLocale: 'ch',
  globalInjection:true,
  legacy: false, // you must specify 'legacy: false' option
  locale: 'zh_CN',
  messages: {'zh_CN':cn, 'en_US':en,},
});

export default i18n