import request from "@/network/request";
import {toRaw} from "vue";


export class API {
    get_obj = (app, model, pk, params=null) => request.get('/' + app +'/'+ model + '/' + pk + '/', {params:params});
    del_obj = (app, model, pk) => request.delete('/' + app +'/'+ model + '/' + pk + '/');
    save_obj(app, model, formState){
        let url = '/' + app +'/'+ model + '/'
        let params = toRaw(formState);
        if (params.id){
            return request.put(url + params.id + '/', params);
        }else{
            return request.post( url  + '0/' , params);
        }
    }

    get_list = (app, model, report_name, params) => request.get('/' + app +'/'+ model + '/' + report_name + '/', {params:params});

    get_calendar_content = (url_params, params) => request.get('/calendar/' + url_params, params);
    get_static_data = () => request.get('/calendar/static_data/');


    get_userprofile = (pk) => request.get('/userprofile/' + pk + '/');
    save_userprofile(formState){let params = toRaw(formState);return request.put('/userprofile/' + params.id + '/', params);}


    upload(info, model, pk, field){
        let param = new FormData()  // 创建form对象
          param.append('file', info.file)  // 通过append向form对象添加数据
          param.append('model', model) // 添加form表单中其他数据
          param.append('pk', pk) // 添加form表单中其他数据
          param.append('field', field) // 添加form表单中其他数据
        let config = {headers: {'Content-Type': 'multipart/form-data'}}

        return request.post('/upload/', param, config);
    }

    get_report = (params) => request.get('/report/', {params:params});
}