const routes = [
    {
        name: 'calendar',
        path: '/',
        component: () => import('@/components/calendar/CalendarBody.vue'),
        meta: {title: '首页', level: 0,}
    },
    {
        name: 'calendar_event',
        path: '/calendar/event/',
        component: () => import('@/components/calendar/CalendarEvents.vue'),
        meta: {title: '事项', level: 999,}
    },
    {
        name: 'calendar_event_list',
        path: '/calendar/event/list/',
        component: () => import('@/components/Layout/Report.vue'),
        props:{app:'gnokCalendar', model:'events', report_name:'List', allow_add:true, route_name_for_add:'calendar_event', header_visible:true},
        meta: {title: '事项列表',level: 100,}
    },
    {
        name: 'ReportBackgroundJobs',
        path: '/Report/BackgroundJobs/',
        component: () => import('@/components/reports/BackgroundJobs.vue'),
        meta: {title: '状态 - 当前任务', level: 100,}
    },
    {
        name: 'ReportCashFlow',
        path: '/Report/CashFlow/',
        component: () => import('@/components/reports/CashFlow.vue'),
        meta: {title: '报告 - 现金流', level: 100,}
    },
    {
        name: 'ReportBalanceSheet',
        path: '/Report/BalanceSheet/',
        component: () => import('@/components/reports/BalanceSheet.vue'),
        meta: {title: '资产 - 负债', level: 100,}
    },
    {
        name: 'TestTable',
        path: '/Test/TestTable/',
        component: () => import('@/components/TestTable.vue'),
        meta: {title: 'test',level: 100,}
    },
    {
        name: 'asset_management_asset',
        path: '/asset/',
        component: () => import('@/components/Layout/EditObj.vue'),
        props:{app:'AssetManagement', model:'asset', report_name:'AssetList', allow_add:true, header_visible:true},
        meta: {title: '资产',level: 999,}
    },
    {
        name: 'asset_management_asset_list',
        path: '/asset/list/',
        component: () => import('@/components/Layout/Report.vue'),
        props:{app:'AssetManagement', model:'asset', report_name:'AssetList', allow_add:true, route_name_for_add:'asset_management_asset', header_visible:true},
        meta: {title: '资产管理',level: 100,}
    },
    {
        name: 'asset_management_meter',
        path: '/asset/meter/',
        component: () => import('@/components/Layout/EditObj.vue'),
        props:{app:'AssetManagement', model:'meter', allow_add:true, header_visible:true},
        meta: {title: '水电表',level: 999,}
    },
    {
        name: 'asset_management_meter_list',
        path: '/asset/meter/list/',
        component: () => import('@/components/Layout/Report.vue'),
        props:{app:'AssetManagement', model:'meter', report_name:'List', allow_add:true, route_name_for_add:'asset_management_meter', header_visible:true},
        meta: {title: '水电表',level: 100,}
    },


];

export default routes