export default {
  message:{
    homePage:'首页',
    logout:'退出',
    show_history:'显示历史记录',
    confirm_delete:'确定删除记录?',
    add_todo:'添加待办',
  },
  menu:{
    data:'数据',
    data_calendar:'日程表',
    data_calendar_event_list:'事项列表',
    dataItem:'事项',
    dataItemNew:'新建事项',
    data_assets_management: '资产管理',
    data_management_assets_list: '资产列表',
    data_management_meter_list: '水电表',
  },
  bn:{
    new:'新建',
    add:'添加',
    copy:'复制',
    delete:'删除',
    history:'历史',
    refresh:'刷新',
    reset_filter:'重置筛选',
  },
  o:{
    event_type:{
      note:'记事',
      agenda: '日程',
      activity:'活动',
      todo: '待办',
      asset:'资产',
      asset_current:'活期',
      asset_deposit:'定期',
      asset_national_debt:'国债',
      asset_financial_products:'理财产品',
      liabilities:'负债',
      liabilities_daily_expenses:'日常开支',
      liabilities_household:'家用',
      liabilities_credit_card:'信用卡',
      festival:'节日',
      festival_birthday:'生日',
      festival_anniversary:'纪念日',
      festival_others:'其它节日',
    },
    festival_type:{
      birthday: '生日',
      anniversary: '纪念日',
      others: '其它',
    },
    repeat_type:{
      no_repeat: '不重复',
      day: '每天',
      week: '每周',
      month: '每月',
      year: '每年',
      lunar_year: '每年(农历)',
    },
    money_sign:{
      income:'收入',
      expense:'支出',
      others:' ',
    },
    done:{'done':'已完成','not done':'未完成','-':'-',},
  },
  field:{
    ' ':' ',
    id: '识别号',
    code: '编码',
    number: '序号',
    type: '类别',
    subject: '标题',
    from_time: '开始时间',
    to_time: '结束时间',
    action_time: '待办时间',
    content: '备注',
    remark: '备注',
    event_type : '分类',
    principal : '本金',
    total_principal : '总本金',
    interest : '利息',
    total_interest : '总利息',
    asset : '资产',
    invest_days : '天数',
    annualized_yield_rate : '收益率',
    done: '完成',
    status: '状态',
    next_run_time: '下次执行时间',
    available: '可用',
    wechat_id: '微信号',
    phone: '电话号码',
    _full_code:'长编码',
    parent_asset_name:'属于',
    parent_asset:'隶属于',
    location: '地点',
    parent_meter:'主表',
  }
}