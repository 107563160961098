export class Dict {
    constructor(utils){
        this.utils = utils
    }

    sync(target, source){
        for (let key in source){
            target[key] = source[key];
        }
    }

    sync_for_DOM(target, source, moment=null){
        for (let key in source){
            if (moment && key.endsWith('_time') && source[key] !== null){
                target[key] = moment(source[key])
            }else{
                target[key] = source[key];
            }
        }
    }

    clearObj(obj){
        Object.keys(obj).map(key => {delete obj[key];})
    }
}
